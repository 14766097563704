.signup-form {
    width: 80%;
    max-width: 450px;
    margin: 2rem auto;
    padding: 20px;
    border: 1px solid #bdc3c7;
    border-radius: 4px;
}

.form-group {
    margin-bottom: 1rem;
}

label {
    display: block;
    margin-bottom: 0.5rem;
}

input {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #bdc3c7;
    border-radius: 4px;
}

button {
    background-color: #34495e;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
}

button:hover {
    background-color: #2c3e50;
}

.error-message,
.success-message {
    margin: 1rem 0;
    padding: 0.5rem;
    border-radius: 4px;
}

.error-message {
    color: #e74c3c;
    background-color: #f9ecec;
    border: 1px solid #e74c3c;
}

.success-message {
    color: #27ae60;
    background-color: #e9f9f0;
    border: 1px solid #27ae60;
}
