.careers-page {
    font-family: Arial, sans-serif;
    max-width: 800px;
    margin: 40px auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.careers-page h1 {
    font-size: 2em;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
}

.careers-page p {
    font-size: 1em;
    color: #555;
    margin-bottom: 20px;
    line-height: 1.5;
}

.apply-btn {
    display: block;
    margin: 0 auto;
    padding: 10px 20px;
    background-color: #2c3e50;
    color: #fff;
    text-align: center;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.apply-btn:hover {
    background-color: #34495e;
}
