.container {
    max-width: 1200px;
    margin: auto;
}

.blog-header {
    background-color: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    position: sticky;
    top: 0;
    z-index: 1000;
}

.blog-header-logo {
    font-weight: bold;
    font-size: 24px;
    text-decoration: none;
}

.custom-logo-size {
    font-size: 1.5rem;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.link-secondary {
    color: #6c757d !important;
    text-decoration: none;
    transition: color 0.3s;
}

.link-secondary:hover {
    color: #343a40 !important;
    text-decoration: underline;
}

.nav-scroller {
    background-color: #f8f9fa;
}

.nav {
    border-bottom: 1px solid #e0e0e0;
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
}

.nav > .p-2 {
    display: inline-block;
    padding: 8px 16px;
}

.nav > .p-2:not(:last-child) {
    margin-right: 8px;
}


