.container {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    padding: 20px;
}

h3.display-4 {
    color: #444;
    font-weight: bold;
    margin-bottom: 20px;
}

.row {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

hr {
    border-top: 1px solid #e0e0e0;
    margin-bottom: 20px;
}

.col-md-9 {
    padding-right: 40px;
    border-right: 1px solid #e0e0e0;
}

strong {
    font-size: 16px;
    color: #007bff;
}

h3.mb-0 {
    font-weight: bold;
    margin-top: 10px;
    font-size: 20px;
}

p {
    color: #666;
    font-size: 14px;
    line-height: 1.6;
}

.stretched-link {
    color: #007bff;
    text-decoration: none;
}

.stretched-link:hover {
    text-decoration: underline;
}

img {
    border-radius: 5px;
}

/* Blog post block styles */
.row.no-gutters {
    margin-bottom: 30px;
}

.col-auto.d-none.d-lg-block {
    padding: 0;
}

/* Subcategory block styles */
div[style*="marginBottom: '2rem'"] {
    margin-top: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
}

h4 {
    color: #333;
    font-weight: bold;
}

/* Sidebar different background for distinction */
.Sidebar {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
}

.card-link {
    color: inherit;
    text-decoration: none;
  }
  
  .card-link:hover .row.no-gutters {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    transform: scale(1.02);
    transition: transform 0.2s, box-shadow 0.2s;
  }
  
