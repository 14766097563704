.about-container {
    max-width: 800px;
    margin: 40px auto;
    padding: 20px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.about-container h1 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
}

.about-container p {
    font-size: 1rem;
    line-height: 1.6;
    color: #555;
    margin-bottom: 20px;
}
