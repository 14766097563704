.container {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    padding: 20px;
}

.row {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    padding: 20px;
}

/* Highlighted Posts Styles */

.col-md-12 .bg-dark {
    background-color: #343a40 !important; /* Dark Gray */
    border-radius: 8px;
    overflow: hidden; /* to ensure image and div roundness aligns */
}

.col-md-12 .bg-dark h1 {
    font-size: 2.5rem;
    color: white;
}

.col-md-12 .bg-dark p.lead {
    font-size: 1.2rem;
    color: white;
}

/* Featured Posts Styles */

.card {
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
}

.card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.img-fluid {
    max-width: 100%;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.card-title {
    font-weight: bold;
    font-size: 1.5rem;
}

.card-text {
    color: #666;
}

.text-body-secondary {
    color: #999;
}

.card-body {
    padding: 15px;
}

a.text-primary {
    color: #007bff !important;
    text-decoration: none;
}

a.text-primary:hover {
    text-decoration: underline;
}

/* Remove default link styles for posts */
.highlighted-post-link, .featured-post-link {
    text-decoration: none;
    color: inherit;
}

/* Add hover styles for entire cards */
.highlighted-post-link:hover, .featured-post-link:hover {
    text-decoration: none;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    transform: translateY(-5px);
}


/* Sidebar */

.Sidebar {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
}

/* You can add more styles for the sidebar content as needed */
