.container.mt-3 {
    margin-top: 40px !important;
}

.display-4 {
    color: #343a40;
    font-weight: 600; 
    margin-bottom: 30px;
    border-bottom: 3px solid #343a40;
    padding-bottom: 10px;
}

.row.no-gutters {
    margin-bottom: 25px;
}

.border.rounded {
    border-color: #e0e0e0 !important;
    overflow: hidden;
}

.shadow-sm {
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}

.h-md-250 {
    height: 250px;
}

.position-static {
    position: relative;
}

.d-inline-block.mb-2.text-primary {
    color: #007bff !important;
    margin-bottom: 5px !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mb-1.text-muted {
    margin-top: 5px;
    color: #6c757d !important;
}

.card-text.mb-auto {
    margin-bottom: auto !important;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 100px;
}

.stretched-link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    color: #007bff;
    text-decoration: none;
}

.stretched-link:hover {
    text-decoration: underline;
}

.col-auto.d-none.d-lg-block img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
