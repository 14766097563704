.faq-container {
    max-width: 800px;
    margin: 40px auto;
    padding: 20px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.faq-container h1 {
    font-size: 2rem;
    margin-bottom: 30px;
    color: #333;
}

.faq-item {
    margin-bottom: 30px;
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 20px;
}

.faq-item:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
}

.faq-item h2 {
    font-size: 1.4rem;
    color: #444;
    margin-bottom: 15px;
}

.faq-item p {
    font-size: 1rem;
    line-height: 1.6;
    color: #555;
}
