.footer {
    background-color: #343a40;
    color: #ffffff;
    padding: 20px 0;
    font-family: Arial, sans-serif;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
}


.footer-logo {
    font-size: 24px;
    font-weight: bold;
}

.footer-links, .footer-social {
    display: flex;
    gap: 20px;
}

.footer-links a, .footer-social a {
    color: #ffffff;
    text-decoration: none;
    transition: color 0.3s;
}

.footer-links a:hover, .footer-social a:hover {
    color: #f8f9fa;
}

.footer-copyright {
    text-align: center;
    margin-top: 10px;
    font-size: 14px;
}
