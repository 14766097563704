.container {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    padding: 20px;
}

.row {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

.col-md-9 {
    padding-right: 40px;
    border-right: 1px solid #e0e0e0;
}

h1.display-4 {
    color: #333;
    font-weight: bold;
    margin-top: 20px;
}

p {
    color: #555;
    line-height: 1.6;
}

img {
    border-radius: 8px;
    max-width: 100%;
    height: auto;
}

hr {
    border-top: 1px solid #e0e0e0;
    margin-top: 40px;
    margin-bottom: 40px;
}

/* Category and Subcategory styles */
div[style*="display: flex"] {
    margin-bottom: 10px;
}

div[style*="display: flex"] span {
    margin: 0 5px;
}

/* Byline styles */
h6 {
    color: #777;
    margin-top: 20px;
}

/* Post update details */
p[style*="color: grey"] {
    margin-top: 10px;
    font-style: italic;
}

/* Sidebar should have a different background for distinction */
.Sidebar {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
}

/* You can continue to add styles for the sidebar contents as needed */
